<template>
<!--  <LxpMobileHeader v-if="!changeSearch" :title="addrModal ? '우편물 수령주소' : '내 정보 수정'">-->
  <LxpMobileHeader v-if="isProfileMain" title="내 정보 수정">
    <template v-slot:left>
      <div class="util util-back">
        <a class="util-actions util-actions-back" @click="goBack"><i class="icon-history-back"></i></a>
      </div>
    </template>
  </LxpMobileHeader>
  <main v-if="isProfileMain" class="kb-main" id="kb-mypage">
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- mypage-header -->
      <div class="mypage-header mg-32">
        <!-- mypage-my -->
        <div class="mypage-my mb-0">
          <div class="my-avatar">
            <div class="image">
              <ProfileImg :target="session" />
            </div>
            <button class="kb-btn-edit" @click="showMyPortraitModal">
              <span class="badge badge-primary"><em class="badge-text">변경</em></span>
            </button>
          </div>
        </div>
        <!-- //mypage-my -->
      </div>
      <!-- //mypage-header -->
      <div class="mypage-body">
        <section class="content-section">
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">이름</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ session.lrnerNm }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">직원번호</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ session.lrnerId }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">직급/직위</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ session.jbgdNm }}/{{ session.jbpsNm }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">소속부점</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ session.deptNm }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">담당직무</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ params.fhnjikmu }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">나의 CDP</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    [직군] {{ params.jobGrpNm }}, [직렬] {{ params.jobFncNm }}, [직무] {{ params.fhnjikmu }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">E-mail</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ params.finemail }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">자택 전화번호</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ params.hoTelno }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">휴대폰 번호</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ params.hpTelno }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">자택주소</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    ({{ params.hoUpbho }}) {{ params.hoAddrRange }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">우편물수령주소</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div class="kb-form-text">
                    {{ params.address }}
                  </div>
                </div>
              </div>
            </div>
            <div class="kb-btn-actions">
              <button class="kb-btn-content-item" @click="changeAddr">우편물 수령주소 변경</button>
            </div>
          </div>
          <div class="content-item silver">
            <div class="desc">
              <p>
                자택주소, 전화번호 정보는 인사시스템(MyHR)에서 직접 변경하시기 바라며, 변경 시 익일 반영됩니다. (경로 : MyHR 시스템 접속 &gt; 직원정보 &gt; 인사기록카드 &gt; 내프로필 &gt; 인적사항)
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <template v-else>
    <MobileMyCommonAddress
        v-model="addrModal"
        :user-addr-info="params"
    />
    <MobileMyPortraitModal />
  </template>
</template>

<script>
import ProfileImg from "@/components/common/ProfileImg";
import {profileSetup} from "@/assets/js/modules/mypage/profile/profile-setup";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import MobileMyCommonAddress from "@/components/mypage/mobile/MobileMyCommonAddress";
import MobileMyPortraitModal from "@/components/mypage/mobile/MobileMyPortraitModal.vue";

export default {
  name: "MobileMyProfile",
  components: {MobileMyCommonAddress, MobileMyPortraitModal, LxpMobileHeader, ProfileImg},
  setup: profileSetup
}
</script>