<template>
  <LxpMobileHeader v-if="modelValue && !isSearchAddr" title="우편물 수령주소">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack('info')">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template  v-slot:right>
      <div class="util util-apply">
        <a href="javascript:" class="util-actions util-actions-yellow" @click="clickSave">변경</a>
      </div>
    </template>
  </LxpMobileHeader>
  <LxpMobileHeader v-else-if="modelValue && isSearchAddr">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack('search')">
          <i class="icon-history-back"></i>
        </a>
      </div>
      <div class="util util-search-input">
        <input v-model.trim="search.keyword" type="text" placeholder="도로명주소, 건물명 또는 지번을 입력해주세요." @keyup.enter.prevent="clickSearch">
      </div>
    </template>
    <template v-slot:right>
      <div class="util util-search">
        <a href="javascript:" class="util-actions util-actions-search" @click="clickSearch">
          <i class="icon-search"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main v-if="modelValue" class="kb-main" id="kb-mypage">
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="mypage-body">
        <section v-if="!isSearchAddr" class="content-section">
          <div class="content-item bd-clear">
            <div class="kb-form-fields">
              <div class="kb-form-item">
                <div class="kb-form-column kb-form-column-title">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">현재주소</span>
                  </label>
                </div>
                <div class="kb-form-column column-whole">
                  <div v-if="userAddrInfo.shipInfoSn > 0" class="kb-form-text">{{`(${userAddrInfo.shipZip}) ${userAddrInfo.shipAddr} ${userAddrInfo.shipDtlAddr}`}}</div>
                  <div v-else class="kb-form-text">-</div>
                </div>
              </div>
            </div>
            <div class="kb-btn-actions">
              <button class="kb-btn-content-item kb-btn-primary" @click="searchAddr">변경할 주소 검색 </button>
            </div>
          </div>
          <template v-if="isSelected">
            <div class="content-item">
              <div class="kb-form-fields">
                <div class="kb-form-item">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">변경할 기본주소</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <div class="kb-form-text">
                      {{ params.shipZip }} | {{ params.shipAddr }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-item">
              <div class="kb-form-fields">
                <div class="kb-form-item">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">변경할 상세주소</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-whole">
                    <input v-model="params.shipDtlAddr" type="text" class="kb-form-control" placeholder="상세주소를 입력하세요.">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </section>

        <div v-if="!isSelected" class="search-result">
          <ul class="search-result-list">
            <!-- search-result-item -->
            <template v-for="(item ,idx) in bodies" :key="`${idx}`" >
              <li class="search-result-item" :id="`result${idx}`">
                <label :for="`result${idx}`" class="search-result-target" @click.stop="selectedAddress(idx)">
                  <span class="text">{{ item.zipNo }}</span>
                  <span class="text bold">{{ item.customAddr }}</span>
                </label>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {myCommonAddressSetup} from "@/assets/js/modules/mypage/profile/my-common-address-setup";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";

export default {
  name: "MobileMyCommonAddress",
  components: {LxpMobileHeader},
  props: {
    modelValue: Boolean,
    userAddrInfo: Object
  },
  emits: ['update:modelValue', 'update:isSearch'],
  setup: myCommonAddressSetup
}
</script>