import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import {getItems, getPaging, isSuccess, lengthCheck} from "@/assets/js/util";
import {ACT_GET_ADDR_LINK_LIST} from "@/store/modules/common/common-index";
import {ACT_INSERT_COM_USERS_SHIP_INFO, ACT_UPDATE_COM_USERS_SHIP_INFO} from "@/store/modules/auth/auth";
import {MUT_SHOW_MY_PROFILE_MAIN} from "@/store/modules/my/my";

export const myCommonAddressSetup = (props, {emit}) =>  {
    const store = useStore();
    const search = reactive({
        keyword: '',
    });

    const isProfileMain = computed(() => store.state.my.isMyProfileMain);

    const {showConfirm, showMessage} = useAlert();

    const params = reactive({
        shipZip:'',
        shipAddr: '',
        shipDtlAddr: '',
        rcvrTelno: ''
    });

    const addrInfo = ref([]);

    const bodies = ref([]);
    const paging = ref({pageNo: 1, pageSize: 10, totalCount: 0});
    const isSearch = ref(false);
    const isSelected = ref(false);

    const isEmpty = computed(() => {
        return (bodies.value.length > 0);
    });

    const closeModal = () => {
        initParams();

        addrInfo.value = [];
        paging.value.totalCount = 0;
        emit('update:modelValue',false);
        store.commit(`my/${MUT_SHOW_MY_PROFILE_MAIN}`);
    };

    const initParams = () => {
        params.shipZip = '';
        params.shipAddr = '';
        params.shipDtlAddr = '';

        isSelected.value = false;
    }

    const clickSave = () => {
        // 부모 컴포넌트에 데이터 전달
        if(!params.shipDtlAddr) {
            showMessage({
                text: '변경할 주소를 입력해주세요.',
            });
        } else {

            showConfirm({
                text: '우편물 수령주소 변경 하시겠습니까?',
                callback: async () => {
                    params.rcvrTelno = props.userAddrInfo.hpTelno;
                    let res = null;

                    if(props.userAddrInfo.shipInfoSn > 0) {
                        res = await updateComUsersShipInfo();
                    } else {
                        res = await insertComUsersShipInfo();
                    }
                    if(isSuccess(res)){
                        showMessage({
                            text: '변경 완료되었습니다.',
                            callback: () => {
                                closeModal();
                            }
                        });
                    }
                }
            })
        }
    }

    const clickSearch = () => {
        paging.value.pageNo = 1;
        if(search.keyword){
            initParams();
            getPostList();
        }else{
            showMessage({
                text: '주소를 입력해주세요.',
            });
        }
    }

    const getPostList = () => {
        store.dispatch(`common/${ACT_GET_ADDR_LINK_LIST}`, {
            keyword: search.keyword,
            // pageNo: paging.value.pageNo,
            // pageSize: paging.value.pageSize
            currentPage: paging.value.pageNo,
            countPerPage: paging.value.pageSize
        }).then(res=>{
            isSearch.value = true;
            if(lengthCheck(res)){
                bodies.value = getItems(res);
                paging.value = getPaging(res);
            }else{
                bodies.value = [];
                paging.value.totalCount = 0;
            }
        }).catch(e=>{
            console.error(e);
        });
    };

    const selectedAddress = (idx) => {
        params.shipZip = bodies.value[idx].zipNo;
        params.shipAddr = bodies.value[idx].roadAddr;
        isSelected.value = true;
        isSearch.value = false;
        isSearchAddr.value = false;
        search.keyword = '';
    }

    const insertComUsersShipInfo = () => {
        return store.dispatch(`auth/${ACT_INSERT_COM_USERS_SHIP_INFO}`, params);
    }

    const updateComUsersShipInfo = () => {
        return store.dispatch(`auth/${ACT_UPDATE_COM_USERS_SHIP_INFO}`, {shipInfoSn: props.userAddrInfo.shipInfoSn, params : params});
    }

    const isSearchAddr = ref(false);
    const searchAddr = () => {
        isSearchAddr.value = true;
    }

    const goBack = (type) => {
        if(type === 'search'){
            initParams();
            search.keyword = '';
            isSearchAddr.value = false;
        } else if(type === 'info') {
            closeModal()
        }
    }

    return {
        search,
        params,
        isSelected,
        isSearch,
        bodies,
        isEmpty,
        addrInfo,
        isProfileMain,
        isSearchAddr,

        searchAddr,
        selectedAddress,
        clickSave,
        closeModal,
        getPostList,
        clickSearch,
        goBack
    }
}