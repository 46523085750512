import {computed, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {ACT_GET_AUTH_PROFILE} from "@/store/modules/auth/auth";
import {getItem, lengthCheck, setParams} from "@/assets/js/util";
import { goBack } from "@/assets/js/modules/common/common";
import {MUT_HIDE_MY_PROFILE_MAIN, MUT_TOGGLE_MY_PORTRAIT_MODAL} from "@/store/modules/my/my";

export const profileSetup = () => {
    const store = useStore();
    const addrModal = ref(false);
    const session = computed(() => store.state.auth.session);

    const setDefaultImg = (e) => {
        e.target.src = require('../../../../../assets/images/profile/sample.png');
    }
    const isMobile = computed(() => store.state.auth.isMobile);
    const isProfileMain = computed(() => store.state.my.isMyProfileMain);

    const params = reactive({
        shipInfoSn: 0,
        emlAddr: '',
        mblTelno: '',
        jobGrpNm: '',
        jobFncNm: '',
        fhnjikmu: '',
        jobNm: '',
        hoTelno: '',
        hpTelno: '',
        hoUpbho: '',
        hoAddre: '',
        hoAddrRange: '',
        address: '',
        finemail: '',

        shipZip: '',
        shipAddr: '',
        shipDtlAddr: '',
    });

    const isLoading = ref(false);
    const isAddr = ref(false);

    const getMyProfile = () => {
        store.dispatch(`auth/${ACT_GET_AUTH_PROFILE}`, {}).then(res => {
            if (lengthCheck(res)) {
                const item = getItem(res);
                setParams(params, item);
                if(item.shipZip && item.shipAddr && item.shipDtlAddr) {
                    isAddr.value = true;
                    params.address = `(${item.shipZip}) ${item.shipAddr} ${item.shipDtlAddr}`;
                } else {
                    isAddr.value = false;
                    params.address = '-'
                }

                isLoading.value = true;
            } else {
                isLoading.value = false;
            }
        }).catch(e => {
            console.error(e);
            isLoading.value = false;
        })
    }

    const profileImg = computed(() => store.state.my.profileImgUrl);

    const showMyPortraitModal= () => {
        if(isMobile.value){
            store.commit(`my/${MUT_HIDE_MY_PROFILE_MAIN}`);
        }
        store.commit(`my/${MUT_TOGGLE_MY_PORTRAIT_MODAL}`);
    }

    const changeAddr = () => {
        addrModal.value = true;
        if(isMobile.value) {
            store.commit(`my/${MUT_HIDE_MY_PROFILE_MAIN}`);
        }
    }
    watch(() => addrModal.value, () => {
        if(!addrModal.value) {
            getMyProfile();
        }
    });

    watch(() => isProfileMain.value, () => {
        getMyProfile();
    });

    onMounted(async () => {
        getMyProfile();
    });
    
    return {
        params,
        session,
        addrModal,
        isLoading,
        isAddr,
        isProfileMain,
        profileImg,

        getMyProfile,
        changeAddr,
        setDefaultImg,
        showMyPortraitModal,
        goBack
    }
}